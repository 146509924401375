import axios from "axios";
import * as ActionType from "./video.type";
import { setToast } from "../../../util/toast";
import { apiInstanceFetch } from "../../../util/api";
import { baseURL } from "../../../util/config";
import { useDispatch } from "react-redux";
export const getVideoApi =
  (type, start, limit, startDate, endDate) => (dispatch) => {
    axios
      .get(
        `${baseURL}admin/video/videosOrShorts?videoType=${type}&start=${start}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.GET_VIDEO,
          payload: {
            video: res.data.videosOrShorts,
            totalVideo: res.data.totalVideosOrShorts,
          },
        });
      })
      .catch((error) => console.error("error", error));
  };
export const getHashTagApi = (start, limit) => (dispatch) => {
  axios
    .get(`${baseURL}admin/hashTag/hashtagForHome?start=${start}&limit=${limit}`)
    .then((res) => {
      dispatch({
        type: ActionType.HOMEHASHTAG,
        payload: {
          totalHashtag: res.data.totalHashTags,
          hashtagData: res.data.hashTags,
        },
      });
    });
};
export const deleteHashtag = (id) => (dispatch) => {
  axios.delete(`${baseURL}admin/hashTag/deleteHashtag?id=${id}`).then((res) => {
    if (res.data.status) {
      dispatch({
        type: ActionType.DELETEHASHTAG,
        payload: { id: id },
      });
      setToast("success", "hashtag is deleted successfully");
    } else {
      setToast("error", res.data.message);
    }
  });
};
export const addHashtag = (data, start, limit) => (dispatch) => {
  console.log("user is here");
  let formatData = JSON.stringify({ hashtag: data });
  console.log(formatData);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}admin/hashTag/addhashtag`,
    headers: {
      "Content-Type": "application/json",
    },
    data: formatData,
  };
  axios
    .request(config)
    .then((res) => {
      if (res.data.status) {
        setToast("success", res.data.message);
        dispatch(getHashTagApi(start, limit));
      }
    })
    .catch((error) => console.log(error));
};
export const editHashtag = (data, start, limit) => (dispatch) => {
  console.log("user is here");
  let formatData = JSON.stringify({ id: data._id, hashtag: data.title });
  console.log(formatData);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}admin/hashTag/updateHashtag`,
    headers: {
      "Content-Type": "application/json",
    },
    data: formatData,
  };
  axios
    .request(config)
    .then((res) => {
      if (res.data.status) {
        setToast("success", res.data.message);
        dispatch(getHashTagApi(start, limit));
      }
    })
    .catch((error) => console.log(error));
};
export const updateHashtag = (Data, start, limit) => (dispatch) => {
  let formatData = JSON.stringify({ feature: Data });
  console.log("data", Data);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}admin/hashTag/updateFeature`,
    headers: {
      "Content-Type": "application/json",
    },
    data: formatData,
  };
  axios
    .request(config)
    .then((res) => {
      console.log(res);
      if (res.data.status) {
        dispatch(getHashTagApi(start, limit));
        setToast("success", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
export const getFakeUserName = () => (dispatch) => {
  axios
    .get(`${baseURL}admin/user/getUsersAddByAdminForChannel`, {
      headers: {
        key: `${localStorage.getItem("key")}`,
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: ActionType.GET_FAKE_USER_NAME,
        payload: res.users,
      });
    })
    .catch((error) => console.error(error));
};

export const createVideo = (Data, feature) => (dispatch) => {
  const formData = new FormData();
  formData.append("videoUrl", Data.videoUrl);
  formData.append("videoImage", Data.videoImage, "thumbnail.jpg");
  formData.append("title", Data.title);
  formData.append("description", Data.description);
  formData.append("hashTag", Data.hashTag);
  formData.append("latitude", Data.latitude);
  formData.append("location", Data.location);
  formData.append("longitude", Data.longitude);
  formData.append("scheduleTime", Data.scheduleTime);
  formData.append("scheduleType", Data.scheduleType);
  formData.append("userId", Data.userId);
  formData.append("videoTime", Data.videoTime);
  formData.append("videoType", Data.videoType);
  formData.append("visibilityType", Data.visibilityType);
  formData.append("channelId", Data.channelId);
  formData.append("audienceType", Data.audienceType);
  formData.append("commentType", Data.commentType);
  formData.append("feature", Data?.feature);

  axios
    .post(`${baseURL}admin/video/uploadVideo`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.loading,
          payload: {
            loading: false,
          },
        });
        dispatch({
          type: ActionType.IMPORT_VIDEO,
          payload: {
            data: res.data.videoSaved,
            totalNumber: res.data.totalVideosOrShorts,
          },
        });

        setToast("success", "Video created Successfully !");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast(error, error.message));
};
export const updateFeature =
  (Data, type, start, limit, startDate, endDate) => (dispatch) => {
    let formatData = JSON.stringify({ feature: Data });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseURL}admin/video/updatefeature`,
      headers: {
        "Content-Type": "application/json",
      },
      data: formatData,
    };
    axios
      .request(config)
      .then((res) => {
        if (res.data.status) {
          dispatch(getVideoApi(type, start, limit, startDate, endDate));
          setToast("success", res.data.message);
        }
      })
      .catch((error) => console.log(error));
  };
export const editVideo =
  (Data, videoId, userId, channelIdFind, type, fullNameUser, uniqueVideoId) =>
  (dispatch) => {
    const formData = new FormData();
    Data.videoUrl == null
      ? formData.append("videoUrl", null)
      : formData.append("videoUrl", Data?.videoUrl);
    Data.videoImage == null
      ? formData.append("videoImage", null)
      : formData.append("videoImage", Data?.videoImage, "thumbnail.jpg");
    formData.append("title", Data.title);
    formData.append("description", Data.description);
    formData.append("hashTag", Data.hashTag);
    formData.append("latitude", Data.latitude);
    formData.append("location", Data.location);
    formData.append("longitude", Data.longitude);
    formData.append("scheduleTime", Data.scheduleTime);
    formData.append("scheduleType", Data.scheduleType);
    formData.append("userId", Data.userId);
    formData.append("videoTime", Data.videoTime);
    formData.append("videoType", Data.videoType);
    formData.append("visibilityType", Data.visibilityType);
    formData.append("channelId", Data.channelId);
    formData.append("audienceType", Data.audienceType);
    formData.append("commentType", Data.commentType);
    axios
      .patch(
        `${baseURL}admin/video/updateVideo?videoId=${videoId}&userId=${userId}&channelId=${channelIdFind}&videoType=${type}&uniqueVideoId=${uniqueVideoId}`,
        formData,
        {
          timeout: 60000 * 10,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        } //increasing the uploading time
      )
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: ActionType.EDIT_VIDEO,
            payload: {
              data: res.data.video,
              videoId: videoId,
              fullName: fullNameUser,
            },
          });
          setToast(
            "success",
            `${
              type === 1 ? "Video Edit SuccessFully" : "Short Edit SuccessFully"
            }`
          );
        } else {
          setToast("error", res.data.message);
        }
      })
      .catch((error) => setToast("error", error.message));
  };

export const deleteVideo = (id) => (dispatch) => {
  axios
    .delete(`${baseURL}admin/video/deleteVideo?videoId=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: ActionType.DELETE_VIDEO, payload: { id: id } });
        setToast("success", "Video Delete SuccessFully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
export const getHashTag = () => (dispatch) => {
  axios
    .get(`${baseURL}admin/hashTag/hashTag`)
    .then((res) => {
      if (res.data.status) {
        console.log("dataaaaa", res.data.hashtag);
        dispatch({
          type: ActionType.HASHTAG,
          payload: { data: res.data.hashtag },
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getCommentsApi =
  (start, limit, startDate, endDate, type) => (dispatch) => {
    axios
      .get(
        `${baseURL}admin/VideoComment/commentsOfVideos?start=${start}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&videoType=${type}`
      )
      .then((res) => {
        dispatch({
          type: ActionType.COMMENT_GET,
          payload: {
            videoComments: res.data.commentsOfVideos,
            totalVideoComment: res.data.totalComments,
          },
        });
      })
      .catch((error) => console.error(error));
  };

export const deleteVideoComments = (id) => (dispatch) => {
  axios
    .delete(
      `${baseURL}admin/videoComment/deleteVideoComment?videoCommentId=${id}`
    )
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.DELETE_VIDEO_COMMENTS,
          payload: { id: id },
        });
        setToast("success", "Comment Delete SuccessFully");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
