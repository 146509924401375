import React, { useEffect, useRef, useState } from "react";
import Button from "../extra/Button";
import Input from "../extra/Input";
import Selector from "../extra/Selector";
import "../../assets/css/style.css";

import {
  getCountry,
  createFakeUser,
  getIpAddress,
  getFakeUserName,
} from "../store/user/user.action";
import {
  createVideo,
  editHashtag,
  editVideo,
  getHashTag,
  updateFeature,
} from "../store/video/video.action";
import { connect, useDispatch, useSelector } from "react-redux";
import { covertURl, uploadFile } from "../../util/AwsFunction";
import noImageFrom from "../../assets/images/noimage.png";
import $ from "jquery";
import {
  CLOSE_DIALOGUE,
  CLOSE_LOADER,
  FEATURE,
} from "../store/dialogue/dialogue.type";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";

import { Box, CircularProgress, Typography } from "@mui/material";
import FeatureToggle, { MaterialUISwitch } from "../extra/ToggleButton";
import SmallLoader from "../extra/SmallLoader";
import { useLocation } from "react-router-dom";
import { CheckBox, Sledding } from "@mui/icons-material";
import TagInput from "../extra/Tag-Input";

function NewHashtagAdd(props) {
  const AgeNumber = Array.from({ length: 100 }, (_, index) => index + 1);
  const { loading } = useSelector((state) => state.video);
  const {
    dialogue,
    dialogueType,
    dialogueData,
    start,
    limit,
    startDate,
    endDate,
  } = useSelector((state) => state.dialogue);
  const { setMultiButtonSelect } = props;
  const dispatch = useDispatch();

  const [title, setTitle] = useState();

  const [hashTag, setHashTag] = useState();

  const [error, setError] = useState({
    title: "",
  });

  useEffect(() => {
    if (!dialogueData) {
      setTitle("");
    }
  }, [dialogueData]);

  useEffect(() => {
    if (dialogueData) {
      setTitle(dialogueData?.hashTag);
    }
  }, [dialogueData]);
  useEffect(() => {
    const fetchData = async () => {
      const fileNameWithExtensionThumbnail = dialogueData?.videoImage
        ?.split("/")
        .pop();
      const { imageURL: videoImage } = await covertURl(
        "videoImage/" + fileNameWithExtensionThumbnail
      );
      const fileNameWithExtensionImage = dialogueData?.videoUrl
        ?.split("/")
        .pop();
      const { imageURL } = await covertURl(
        "Videos/" + fileNameWithExtensionImage
      );
    };
    if (dialogueData) {
      fetchData();
      const interval = setInterval(fetchData, 1000 * 60);
      return () => clearInterval(interval);
    }
  }, [dialogueData]);

  const handleClose = () => {
    dispatch({
      type: CLOSE_DIALOGUE,
      payload: {
        dialogue: false,
        dialogueData: dialogueData,
      },
    });
    let dialogueData_ = {
      dialogue: false,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const handleSubmits = () => {
    console.log(title);
    const check = title.startsWith("#");
    console.log(check);
    if (!check) {
      error.title = "hashTag must start with #";
      return setError({ ...error });
    }
    if (!title) {
      const error = {};

      if (!title) error.title = "HashTag Is Required !";

      return setError({ ...error });
    } else {
      let newVideoAddData = {
        _id: dialogueData._id,
        title: title,
      };

      if (dialogueData) {
        props.editHashtag(newVideoAddData, start, limit);
        handleClose();
      } else {
        props.createVideo(newVideoAddData);

        /*setTimeout(() => {
          setMultiButtonSelect("Manage Video");
        }, 500);*/
      }
    }
  };

  const minDate = () => {
    const today = new Date().toISOString().split("T")[0];
    return today;
  };

  return (
    <div>
      <div className="general-setting fake-user video-upload-page mt-3">
        <div className=" userSettingBox">
          <form>
            <div className="row d-flex  align-items-center">
              <div className="col-6">
                <h5 className="mb-0 text-nowrap">
                  {dialogueType ? "Edit Hashtag" : "Import Video"}
                </h5>
              </div>
              {dialogueType && (
                <div className="col-6 d-flex justify-content-end">
                  <Button
                    btnName={"Back"}
                    newClass={"back-btn"}
                    onClick={handleClose}
                  />
                </div>
              )}
              <form>
                <div
                  className="col-12 d-flex justify-content-end align-items-center"
                  style={{
                    paddingTop: "23px",
                    marginTop: "11px",
                    borderTop: "1px solid #c9c9c9",
                  }}
                >
                  <Button
                    newClass={"submit-btn"}
                    btnName={"Submit"}
                    type={"button"}
                    onClick={handleSubmits}
                  />
                </div>
                <div className="row ">
                  <div className="col-12 col-lg-6 col-sm-6 col-xs-12 mt-2">
                    <Input
                      label={"Hashtag"}
                      name={"hashtag"}
                      value={title}
                      placeholder={"Enter Details..."}
                      errorMessage={error.title && error.title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            title: `Hashtag Is Required`,
                          });
                        } else {
                          return setError({
                            ...error,
                            title: "",
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default connect(null, {
  editHashtag,
  getCountry,
  createFakeUser,
  getFakeUserName,
  getIpAddress,
  createVideo,
  editVideo,
  updateFeature,
})(NewHashtagAdd);
