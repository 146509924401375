import React, { useCallback, useState } from "react";
import { ReactTags } from "react-tag-autocomplete";
import "../../assets/css/style.css"; // Import the CSS file
import { error } from "jquery";

export default function TagInput({
  selected,
  setSelected,
  handleHashTagChange,
  error,
  setError,
  suggestions,
}) {
  /*const suggestions = [
    { value: 1, label: "Basketball" },
    { value: 2, label: "Tennis" },
    { value: 3, label: "soccer" },
    { value: 4, label: "funny" },
    { value: 5, label: "sport" },
    { value: 6, label: "rowing" },
    { value: 7, label: "cycling" },
    { value: 8, label: "running" },
    { value: 9, label: "throwing" },
    { value: 10, label: "cricket" },
  ];*/

  const onAdd = useCallback(
    (newTag) => {
      setSelected([...selected, newTag]);
    },
    [selected]
  );

  const onDelete = useCallback(
    (tagIndex) => {
      setSelected(selected.filter((_, i) => i !== tagIndex));
    },
    [selected]
  );
  const handleFocus = () => {
    if (error.hashTag) {
      error.hashTag = "";
      setError({ ...error });
    }
  };
  const onInput = (event) => {
    setSelected({ label: event.target.value });
  };
  return (
    <ReactTags
      selected={selected}
      suggestions={suggestions}
      onAdd={onAdd}
      onDelete={onDelete}
      noOptionsText="No matching Tags"
      onFocus={handleFocus}
    />
  );
}
